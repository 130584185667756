const epoch = 19650;
export const getIdByDate = (date) => {
  let days = date.getTime() / (1000 * 3600 * 24);
  return Math.floor( days - epoch );
}

export const getDateById = id => {
  let day = parseInt(id) + epoch + 1;
  let ms = day * 1000 * 3600 * 24;
  let date = new Date(ms);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
}