// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAY_apakJHpjapl8WAOirWY0PxmQzE7i4M",
  authDomain: "topicle-c8d06.firebaseapp.com",
  projectId: "topicle-c8d06",
  storageBucket: "topicle-c8d06.appspot.com",
  messagingSenderId: "1036093334138",
  appId: "1:1036093334138:web:27b8474ec841798a4d11c3",
  measurementId: "G-E3WP2FV95W"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

