import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import {app} from './firebase';
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { collection, getFirestore, doc, setDoc, deleteDoc, query, where, getDocs, documentId, and } from "firebase/firestore";
import {getIdByDate} from "./utils";

const provider = new GoogleAuthProvider();

const auth = getAuth(app);
const db = getFirestore(app);
const coll = await collection(db, "questions");

const Page = styled.div`
  background-color: white;
`;

function getDaysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}
const d = new Date();
let currentMonth = d.getMonth();
let currentYear = d.getFullYear();
const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032];

// This list must be copied on the firestore write rules
let admins = ['dchartfield', 'benjamin.j.belloli', 'leeadams84', 'adambrownguitar', 'theforerunner343'];
admins = admins.map(a => `${a}@gmail.com`);
let retrievalCounter = 0;
const getQuestionsFor = async (dInM, month, year, counter, setQuestionsById) => {
  let idOfFirstOfMonth = getIdByDate(new Date(year, month, 0));
  let q = query( coll, and(where('idn','>=', idOfFirstOfMonth), where('idn', '<', idOfFirstOfMonth + dInM) ) );
  const querySnapshot = await getDocs(q);
  let retrievedQuestionsById = {};
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let data = doc.data();
    retrievedQuestionsById[data.idn] = data;
  });
  if (counter === retrievalCounter) {
    setQuestionsById(retrievedQuestionsById);
  }
}

let now = new Date();
let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
let todaysId = getIdByDate(today);

function Admin() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [daysInMonth, setDaysInMonth] = useState(getDaysInMonth(currentMonth, currentYear));
  const [questionsById, setQuestionsById] = useState({});
  const [focusedQuestion, setFocusedQuestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [idEditable, setIdEditable] = useState(false);
  useEffect(() => {
    setIdEditable(false);
  }, [!!focusedQuestion]);
  useEffect(() => {
    setLoading(true);
    let dInM = getDaysInMonth(month, year);
    setDaysInMonth(dInM);
    let currentCounter = ++retrievalCounter;
    getQuestionsFor(dInM, month, year, currentCounter, setQuestionsById).finally(() => {
      if ( currentCounter === retrievalCounter ) {
        setLoading(false);
      }
    });
  }, [month, year]);

  useEffect(() => {
    auth.onAuthStateChanged(function(user) {
      if (user) {
        setUser(user);
        setIsAdmin(!!( user && admins.indexOf((user.email || '').toLowerCase()) >= 0) );
        // User is signed in.
      }
    });
  }, []);
  const login = () => {
    signInWithPopup(auth, provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      setUser(result.user);

      setIsAdmin(!!( result.user && admins.indexOf((result.user.email || '').toLowerCase()) >= 0) );
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }
  useEffect(() => {

  }, [user]);

  const signout = () => {
    signOut(auth).then(() => {
      setIsAdmin(false );
      setUser(null);
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
  const editQuestion = question => {
    setFocusedQuestion({
      ...question,
      answers: [...question.answers],
      id: question.idn,
    });
  };
  const deleteQuestion = question => {
    let mergeObj = {};
    mergeObj[question.idn] = null;
    deleteDoc(doc(db, "questions", `${question.idn}`)).then(() => setQuestionsById(Object.assign({}, questionsById, mergeObj)));
  };
  const openCreateQuestionForId = id => {
    setFocusedQuestion({
      id,
      idn: id,
      question: '',
      answers: [],
      topic: '',
    })
  };
  const save = async () => {
    setLoading(true);
    try {
      try {
        await setDoc(doc(db, "questions", `${focusedQuestion.id}`), {
          idn: parseInt(focusedQuestion.id),
          question: `${focusedQuestion.question}`,
          topic: `${focusedQuestion.topic}`,
          answers:  (focusedQuestion.answers || []).map(a => `${a}`),
        });
      } catch (e) {
        console.log(e);
        alert("error while saving: " + e.getMessage());
        throw e;
      }
      setFocusedQuestion(null);
      let currentCounter = ++retrievalCounter
      getQuestionsFor(daysInMonth, month, year, currentCounter, setQuestionsById).finally(() => {
        if (currentCounter === retrievalCounter ) {
          setLoading(false);
        }
      });
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }
  return (
      <Page>
        {
          user ? isAdmin ? (
              <div>
                <h1>Admin</h1>
                <button onClick={() => signout()}>Sign Out of {user.email}</button>
                <hr/>
                <div>
                  {
                    loading ? (
                        <div>Loading...</div>
                    ) : focusedQuestion ? (
                      <div>
                        <button onClick={() => setFocusedQuestion(null)}>Back</button>
                        <br/>
                        <br/>
                        <div>
                          ID:
                          <input
                              disabled={!idEditable}
                              value={focusedQuestion.id}
                              onChange={e => setFocusedQuestion(Object.assign({}, focusedQuestion, {id: e.target.value}))}
                          />
                        </div>
                        <br/>
                        <div>
                          Question:
                          <textarea value={focusedQuestion.question} onChange={e => {
                            setFocusedQuestion(Object.assign({}, focusedQuestion, {question: e.target.value}));
                          }}></textarea>
                        </div>
                        <br/>
                        <div>
                          Answers will show in a random order for users. (Not necessarily the order below.)
                        </div>
                        {
                          Array.from(Array(25).keys()).map(answerIndex => {
                            return (
                                <div key={answerIndex}>
                                  Answer #{answerIndex + 1}:
                                  <input value={(focusedQuestion.answers || [])[answerIndex]} onChange={e => {
                                    let answersCopy = (focusedQuestion.answers || []).slice();
                                    answersCopy[answerIndex] = e.target.value;
                                    setFocusedQuestion(Object.assign({}, focusedQuestion, {answers: answersCopy}))
                                  }}/>
                                  {
                                    answerIndex <= 4 ? (
                                        <span>
                                          &nbsp;(This is correct Answer #{answerIndex + 1})
                                        </span>
                                    ) : null
                                  }
                                </div>
                            )
                          })
                        }
                        <br/>
                        <div>
                          Topic:
                          <input value={focusedQuestion.topic} onChange={e => {
                            setFocusedQuestion(Object.assign({}, focusedQuestion, {topic: e.target.value}))
                          }}/>
                        </div>
                        <br/>
                        {
                          focusedQuestion.answers.map(a => a ? a.trim().toLowerCase() : a).reduce((keysAndDups, a) => {
                            if ( keysAndDups.keys.indexOf(a) >= 0 ) {
                              keysAndDups.dups.push(a);
                            } else {
                              keysAndDups.keys.push(a);
                            }
                            return keysAndDups;
                          }, {keys: [], dups: []}).dups.map(d => (
                              <div key={d} style={{color: 'red',}}>
                                Potential Duplicate Answer: {d}
                              </div>
                          ))
                        }
                        <button
                            onClick={save}
                            disabled={!(focusedQuestion.question && focusedQuestion.question.trim()) || ((focusedQuestion.answers || []).filter(a => a && a.trim()).length !== 25)}
                        >Save</button>
                        <div>Question is filled in? {focusedQuestion.question && focusedQuestion.question.trim() ? 'YES' : 'NO'}</div>
                        <div>Answers Filled in (Should be 25)? {(focusedQuestion.answers || []).filter(a => a && a.trim()).length}</div>

                        <br/>
                        <br/>
                        <br/>
                        {
                          !idEditable ? (
                              <button onClick={() => setIdEditable(true)}>Dev Mode (David says don't push this button)</button>
                          ) : null
                        }
                        <br/>
                        <br/>
                        <br/>
                      </div>
                    ) : (
                        <React.Fragment>
                          <select onChange={e => {
                            setMonth(months.findIndex(m => m === e.target.value));
                          }} value={months[month]}>
                            {months.map((m, i) => {
                              return (
                                  <option
                                      key={m}
                                      value={m}
                                      month={i}
                                  >{m}</option>
                              )
                            })}
                          </select>
                          <select onChange={e => setYear(parseInt(e.target.value))} value={year}>
                            {years.map(y => {
                              return (
                                  <option
                                      key={y}
                                      value={y}
                                  >{y}</option>
                              )
                            })}
                          </select>
                          <table>
                            <tbody>
                              {
                                Array.from(Array(daysInMonth).keys()).map(day => {
                                  let thisDate = new Date(year, month, day + 1, 0, 0, 0);
                                  let thisId = getIdByDate(thisDate);
                                  let thisQuestion = questionsById[`${thisId}`];
                                  let valid = thisQuestion && thisQuestion.question && thisQuestion.answers && thisQuestion.answers.length === 25;
                                  let beforeToday = thisId < todaysId
                                  return (
                                    <tr key={day} style={{
                                    }}>
                                      <td style={{
                                        padding: '8px 0',
                                      }}>{thisDate.toDateString()}</td>
                                      <td>#{thisId}</td>
                                      {
                                        thisQuestion ? (
                                            <React.Fragment>
                                              <td>
                                                <button onClick={() => editQuestion(thisQuestion)} disabled={beforeToday}>Edit Question</button>
                                              </td>
                                              <td style={{
                                                color: valid ? 'blue' : 'red',
                                                fontWeight: 'bold',
                                              }}>&nbsp;{thisQuestion.question}&nbsp;</td>
                                              <td>Answer Count:
                                                <span style={{
                                                  fontWeight: 'bold',
                                                  color: valid ? 'black' : 'red'
                                                }}>{(thisQuestion.answers || []).length}</span>
                                                &nbsp;
                                              </td>
                                              <td>
                                                <button onClick={() => deleteQuestion(thisQuestion)}>Delete</button>
                                              </td>
                                              <td>&nbsp;{thisQuestion.topic}</td>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                              <td>
                                                <button onClick={() => openCreateQuestionForId(thisId)} disabled={beforeToday}>Create Question</button>
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </React.Fragment>
                                        )
                                      }
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </React.Fragment>

                    )
                  }
                </div>
              </div>
          ) : (
              <div>
                <div>
                  {`Who do you think you are ${user.email}? I dunno you!`}
                </div>
                <button onClick={() => signout()}>Sign Out of {user.email}</button>
              </div>
          ) : (
              <div>
                <div>
                  Not logged In
                </div>
                <button onClick={() => login()}>Login</button>
              </div>
          )
        }
      </Page>
  );
}


export default Admin;

