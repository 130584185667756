import logo from './logo.svg';
import styled, {css, keyframes} from "styled-components";
import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import copy from 'copy-to-clipboard';
import {getFirestore, doc, getDoc} from "firebase/firestore";
import {app} from "./firebase";
import {getDateById, getIdByDate} from "./utils";
import cal from './cal.svg';
import {useLocation, useNavigate} from "react-router-dom";

const db = getFirestore(app);


const horizontalMargin = '48px';
const horizontalMarginMobile = '8px';
const yellow = '#FFEB7D';
const green = 'green';
const incorrectBkg = '#6A6A6A';
const animationSec = 0.4;


export const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, []);

  return width;
}

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgray;
  padding: 0 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 50;
`;
const HeaderPlaceholder = styled.div`
  height: 61px;
  width: 100%;
`;
const HeaderLogoButton = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
`;
const HeaderRight = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
`;
const HeaderRightButton = styled.button`
  border: 0px solid transparent;
  background-color: transparent;
  cursor: pointer;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  @media (min-width: 481px) {
    width: 480px;
    max-width: 480px;
    min-width: unset;
    padding-top: 100px;
  }
`;
const HowTo = styled.div`
  color: lightgray;
  margin: 28px 8px 8px;
`;
const ArchiveDate = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: lightgray;
  text-align: center;
  margin: 0 0 0;
  @media (min-width: 481px) {
    margin: 16px 0 0;
  }
`;

const Question = styled.p`
  text-align: center;
  font-weight: bold;
  color: #4CAF50;
`;
const AnswerSection = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2px ${horizontalMarginMobile};

  @media (min-width: 481px) {
    margin-left: ${horizontalMargin};
    margin-right: ${horizontalMargin};
  }
`;

const EntryHeader = styled.div`
  width: 65px;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const sharedEntryCSS = `
  box-sizing: border-box;
  width: 65px;
  min-width: 65px;
  min-height: 45px;
  border: 1px solid lightgray;
  padding: 0px 2px;
  font-size: 10px;
  text-align: center;
  overflow-wrap: anywhere;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const toGreenKey = keyframes`
    0% {
      background-color: black;
      color: lightgray;
    }
    100% {
      background-color: ${green};
      color: white;
    }
`;
const toYellowKey = keyframes`
    0% {
      background-color: black;
      color: lightgray;
    }
    100% {
      background-color: ${yellow};
      color: black;
    }
`;
const toGrayKey = keyframes`
    0% {
      background-color: black;
      color: lightgray;
    }
    100% {
      background-color: ${incorrectBkg};
      color: white;
    }
`;
const toGrayOpacityKey = keyframes`
    0% {
      background-color: black;
      color: lightgray;
      opacity: 1;
    }
    100% {
      background-color: ${incorrectBkg};
      color: lightgray;
      opacity: 0.4;
    }
`;

const Entry = styled.div`
  ${sharedEntryCSS};
  border-top-left-radius: ${({isfirst}) => isfirst ? '4px' : '0px'};
  border-bottom-left-radius: ${({isfirst}) => isfirst ? '4px' : '0px'};
  border-top-right-radius: ${({islast}) => islast ? '4px' : '0px'};
  border-bottom-right-radius: ${({islast}) => islast ? '4px' : '0px'};
  margin: 0 1px;
  border-color: ${
          ({selectedstatus}) => {
            switch(selectedstatus) {
              case 4:
                return green;
              default:
                return 'white';
            }
          }
  };
  background-color: ${
          ({selectedstatus}) => {
            switch(selectedstatus) {
              case 1:
                return green;
              case 2:
                return yellow;
              case 3:
                return incorrectBkg;
              case 4:
                return 'white';
              default:
                return 'transparent';
            }
          }
  };
  color: ${
          ({selectedstatus}) => {
            switch(selectedstatus) {
              case 1:
                return 'white';
              case 2:
                return 'black';
              case 3:
                return 'white';
              case 4:
                return 'black';
              default:
                return 'lightgray';
            }
          }
  };
  animation: ${
    ({selectedstatus}) => {
      switch(selectedstatus) {
        case 1:
          return toGreenKey;
        case 2:
          return toYellowKey;
        case 3:
          return toGrayKey;
        case 4:
          return 'none;'
        default:
          return 'none';
      }
    }
  } ${animationSec}s;
`;
const OptionSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px ${horizontalMarginMobile} 2px;
  @media (min-width: 481px) {
    margin-left: ${horizontalMargin};
    margin-right: ${horizontalMargin};
  }
`;
const OptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2px 0px 2px;
`;
const Option = styled.button`
  ${sharedEntryCSS};
  margin: 0 2px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
  background-color: ${
    ({selectedstatus}) => {
      switch(selectedstatus) {
        case 1:
          return green;
        case 2:
          return yellow;
        case 3:
          return incorrectBkg;
        default:
          return 'black';
      } 
    }
  };
  color: ${
    ({selectedstatus}) => {
      switch(selectedstatus) {
        case 1:
          return 'white';
        case 2:
          return 'black';
        case 3:
          return 'lightgray';
        default:
          return 'lightgray';
      } 
    }
  };
  opacity: ${
    ({selectedstatus}) => {
      switch(selectedstatus) {
        case 3:
          return '0.4';
        default:
          return '1';
      } 
    }
  };
  animation: ${
    ({selectedstatus}) => {
      switch(selectedstatus) {
        case 1:
          return toGreenKey;
        case 2:
          return toYellowKey;
        case 3:
          return toGrayOpacityKey;
        default:
          return 'none';
      }
    }
  } ${animationSec}s;
`;
const CommandButton = styled.button`
  width: 65px;
  height: 45px;
  border: 1px solid lightgray;
  color: lightgray;
  background-color: #444;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 2px;
`;
const CloseButton = styled.button`
  border: 0px solid transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 25px;
  background-color: transparent;
`;
const ResultsBoxRow = styled.div`
  margin: 2px 0; 
`;
const ShareButton = styled.button`
  padding-top: 14px;
  padding-bottom: 14px;
  width: 135px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
`;
const PlayArchiveButton = styled.button`
  border: 1px solid ${green};
  border-radius: 4px;
  width: 80px;
  cursor: pointer;
  background-color: ${green};
  color: white;
  margin: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const greenSquare = '🟩';
const yellowSqure = '🟨';
const blackSquare = '⬛';
const squareByNumber = {
    1: greenSquare,
    2: yellowSqure,
    3: blackSquare,
};

// declare the function
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const defaultAnswerSet = [null, null, null, null, null];
const defaultAnswerSets = [
  defaultAnswerSet.slice(),
  defaultAnswerSet.slice(),
  defaultAnswerSet.slice(),
  defaultAnswerSet.slice(),
  defaultAnswerSet.slice(),
];


let now = new Date();
const todaysId = getIdByDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));

function App() {
  const {
    pathname,
    search,
  } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search)
  const archive = queryParams.get("archive")

  const width = useWindowWidth();
  const [question, setQuestion] = useState('Loading...');
  const [qId, setQId] = useState(-1);
  const [correct, setCorrect] = useState([]);
  const [options, setOptions] = useState(null);

  const [answerSets, setAnswerSets] = useState(defaultAnswerSets);

  const [currentSet, setCurrentSet] = useState(0);
  const [statusByOptionIndex, setStatusByOptionIndex] = useState({});

  const [won, setWon] = useState(false);
  const [done, setDone] = useState(false);
  const [finalSet, setFinalSet] = useState(-1);
  const [modalOpen, setModalOpen] = useState(0);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    (async () => {
      const idToGet = archive ? archive : todaysId;
      const docRef = doc(db, "questions", `${idToGet}`);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.error(`No data for today ${idToGet} ${new Date()}`);
        return;
      }
      let question = docSnap.data();
      let q = question.question;
      let qId = question.idn;
      let list = question.answers;

      let foundItemString = localStorage.getItem(`q${qId}`);
      let foundItem = foundItemString ? JSON.parse(foundItemString) : null;

      let shuffledList = foundItem && foundItem.shuffledList || shuffle(list.slice());

      setQId(qId);
      setQuestion(q);
      setOptions(shuffledList);
      setCorrect(list.slice(0, 5));
      if ( !foundItem ) {
        localStorage.setItem(`q${qId}`, JSON.stringify({shuffledList}));
      } else {
        if ( foundItem.answerSets && Array.isArray(foundItem.answerSets) && foundItem.answerSets.length === defaultAnswerSets.length ) {
          let newAnswerSets = foundItem.answerSets.slice();
          newAnswerSets = newAnswerSets.map(answerSet => {
            return answerSet.length === defaultAnswerSet.length ? answerSet : defaultAnswerSet.slice();
          })
          setAnswerSets(newAnswerSets);
          let firstEmpty = newAnswerSets.findIndex(answerSet => !answerSet[0]);
          firstEmpty = firstEmpty >= 0 ? firstEmpty : newAnswerSets.length;
          setCurrentSet( firstEmpty );

          let toMerge = {};
          newAnswerSets.map((answerSet, answerSetIndex) => {
            answerSet.map((answer, answerIndex) => {
              if ( answer ) {
                let result = testAnswerAtIndex(answer, answerIndex, list.slice(0, 5));
                toMerge[answer.index] = result;
              }
            });
          })
          setStatusByOptionIndex(toMerge);


          let correctCount = getCorrectCountByStatusByOptionSet(toMerge);
          if ( correctCount >= 5 ) {
            setWon(true);
            setDone(true);
            setModalOpen(1);
            setFinalSet(firstEmpty);
          }
          if ( firstEmpty > newAnswerSets.length - 1 ) {
            setFinalSet(firstEmpty);
            setDone(true);
            setModalOpen(1);
          }
        }
      }
    })();
  }, []);

  const getHighestSelectedPosition = () => {
    let answerSet = answerSets[currentSet];
    let highestPositionSet = -1;
    for (let i = answerSet.length - 1; i >= 0; i--) {
      if (answerSet[i]) {
        highestPositionSet = i;
        break;
      }
    }
    return highestPositionSet;
  }
  const testAnswerAtIndex = (answer, index, usedCorrect) => {
    if ( usedCorrect[index] === answer.text ) {
      return 1;
    }
    return usedCorrect.indexOf( answer.text ) >= 0 ? 2 : 3;
  }
  const getCorrectCountByStatusByOptionSet = usedStatusByOptionSet => {
    return Object.values(usedStatusByOptionSet).reduce((col, val) => {
      if ( val === 1 ) {
        col++;
      }
      return col;
    }, 0);
  }
  const onSelectOption = optionIndex => {
    let highestPositionSet = getHighestSelectedPosition();
    let answerSet = answerSets[currentSet];
    if ( highestPositionSet === answerSet.length - 1 ) {
      return;
    }
    if ( answerSet.find(answer => answer && answer.index === optionIndex) ) {
      return;
    }
    let shallowClone = answerSet.slice();
    shallowClone[highestPositionSet + 1] = {text: options[optionIndex], index: optionIndex};
    let answerSetClone = answerSets.slice();
    answerSetClone[currentSet] = shallowClone;
    setAnswerSets(answerSetClone);
  };
  const goBack = () => {
    if ( currentSet >= answerSets.length ) {
      return;
    }
    let highestPositionSet = getHighestSelectedPosition();
    if ( highestPositionSet < 0 ) {
      return;
    }
    let answerSet = answerSets[currentSet];
    let shallowClone = answerSet.slice();
    shallowClone[highestPositionSet] = null;
    let answerSetClone = answerSets.slice();
    answerSetClone[currentSet] = shallowClone;
    setAnswerSets(answerSetClone);
  };
  const submit = () => {
    if ( currentSet >= answerSets.length ) {
      return;
    }
    let highestPositionSet = getHighestSelectedPosition();
    let answerSet = answerSets[currentSet];
    if ( highestPositionSet < answerSet.length - 1 ) {
      return;
    }
    let toMerge = {};
    let shallowClone = answerSet.map((answer, index) => {
      let result = testAnswerAtIndex(answer, index, correct);
      toMerge[answer.index] = result;
      return {
        ...answer,
        result,
      }
    });
    let answerSetClone = answerSets.slice();
    answerSetClone[currentSet] = shallowClone;
    setAnswerSets(answerSetClone);

    let newStatusByOptionSet = Object.assign({}, statusByOptionIndex, toMerge);
    setStatusByOptionIndex(newStatusByOptionSet);
    let correctCount = getCorrectCountByStatusByOptionSet(newStatusByOptionSet);
    let isDone = false;
    if ( correctCount >= 5 ) {
      setWon(true);
      isDone = true;
    }
    if ( currentSet >= answerSets.length - 1 ) {
      isDone = true;
    }
    setDone(isDone);
    if ( isDone ) {
      setTimeout(() => {
        setModalOpen(1);
      }, animationSec * 1000)
      setFinalSet(currentSet + 1);

      let completedResultsString = localStorage.getItem(`completed`);
      let completedResults = completedResultsString ? JSON.parse(completedResultsString) : [];
      completedResults.push(qId);
      localStorage.setItem(`completed`, JSON.stringify(completedResults));
    }
    setCurrentSet(currentSet + 1);

    let retrieved = JSON.parse(localStorage.getItem(`q${qId}`));
    localStorage.setItem(`q${qId}`, JSON.stringify(Object.assign({}, retrieved, {
      done: isDone,
      answerSets: answerSetClone,
    })));
  };
  const share = async () => {
    let text = `Topicle (${won ? finalSet : 'X'}/${answerSets.length})`;
    text += `\n${question}`;
    answerSets.map(answerSet => {
      if ( answerSet && answerSet[0] ) {
        text += '\n';
        answerSet.map(answer => {
          if (answer) {
            text += squareByNumber[answer.result];
          }
        });
      }
    });
    text += `\nhttps://topicle.app `;
    copy(text);
    if ( navigator && navigator.share ) {
      let shareData = {
        text,
        title: 'Topicle.app',
      };
      await navigator.share(shareData);
    } else {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  const openArchive = () => {
    setModalOpen(2);
  }

  const toggleModal = () => setModalOpen(0);
  return (
    <AppWrapper>
      <Modal
        isOpen={!!modalOpen}
        onRequestClose={toggleModal}
        contentLabel="Game Over"
        style={width >= 481 ? {
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
          overlay: {
            zIndex: 100,
          }
        } : {
          content: {
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            padding: '30px 0px 0 0',
          },
          overlay: {
            zIndex: 100,
            inset: '15px',
          }
        }}
      >
        {
          modalOpen === 1 ? (
            <div style={{padding: '0 40px 20px 40px', marginTop: '-20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}>
                <img src={logo} className="App-logo" alt="logo" />
                <CloseButton onClick={toggleModal}>
                    X
                </CloseButton>
                {
                    done ? (
                        <React.Fragment>
                          <div>{won ? 'SUCCESS!' : 'MISSED IT'}</div>
                          <div style={{marginTop: 25, marginBottom: 25,}}>
                            {
                              answerSets.map((answerSet, answerSetIndex) => (
                                <ResultsBoxRow key={answerSetIndex}>
                                    {
                                        answerSet.map(answer => !answer ? '' : squareByNumber[answer.result]).join(' ')
                                    }
                                </ResultsBoxRow>
                              ))
                            }
                          </div>
                        </React.Fragment>
                    ) : null
                }
                <ShareButton onClick={share}>{copied ? 'Copied!' : 'Share'}</ShareButton>
            </div>
          ) : modalOpen === 2 ? (
            <div style={{padding: '0 40px 20px 40px', marginTop: '20px', display: 'block', justifyContent: 'center', maxHeight: '80vh',}}>
              <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'bold',}}>
                <img src={logo} className="App-logo" alt="logo" />
                <p>Play Previous Challenges</p>
              </div>
              <CloseButton onClick={toggleModal}>
                X
              </CloseButton>
              <div style={{
                maxWidth: 300,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
                {
                  Array.from(Array(todaysId).keys()).reverse().map(id => {
                    if ( id <= 0 ) {
                      return;
                    }
                    return (
                        <PlayArchiveButton key={id} onClick={() => {
                          queryParams.set('archive', `${id}`);
                          navigate(pathname + '?' + queryParams.toString());
                          navigate(0);
                        }}>{id}</PlayArchiveButton>
                    )
                  })
                }
              </div>
            </div>
          ) : null
        }
      </Modal>
      <Header>
        <HeaderLogoButton onClick={() => {
          navigate('/');
          navigate(0);
        }}>
          <img src={logo} className="App-logo" alt="logo" />
        </HeaderLogoButton>
        <HeaderRight>
          <HeaderRightButton onClick={openArchive}>
            <img src={cal} alt="archive"/>
          </HeaderRightButton>
        </HeaderRight>
      </Header>
      <HeaderPlaceholder/>
      <Body>
        <Container>
          {
            archive ? (
                <ArchiveDate>#{archive}&nbsp;&nbsp;&nbsp;{getDateById(archive).toDateString()}</ArchiveDate>
            ) : null
          }
          <Question>{question}</Question>
          <AnswerSection>
            <Row>
              <EntryHeader>1st</EntryHeader>
              <EntryHeader>2nd</EntryHeader>
              <EntryHeader>3rd</EntryHeader>
              <EntryHeader>4th</EntryHeader>
              <EntryHeader>5th</EntryHeader>
            </Row>
            {
              answerSets.map((answerSet, answerSetIndex) => {

                return (
                    <Row key={answerSetIndex}>
                      {
                        answerSet.map((answer, answerIndex) => {

                          return (
                              <Entry
                                  key={answerIndex}
                                  selectedstatus={answer ? answer.result : null}
                                  isfirst={answerIndex === 0}
                                  islast={answerIndex === answerSet.length - 1}
                              >
                                {answer ? answer.text : null}
                              </Entry>
                          );
                        })
                      }
                    </Row>
                )
              })
            }
            {
              done ? (
                  <Row>
                    {
                      correct.map((correctItem, correctIndex) => {

                        return (
                            <Entry
                                key={correctIndex}
                                selectedstatus={4}
                                isfirst={correctIndex === 0}
                                islast={correctIndex === correct.length - 1}
                            >
                              {correctItem}
                            </Entry>
                        );
                      })
                    }
                  </Row>
              ) : null
            }
          </AnswerSection>
          <OptionSection>
            {
              [0, 5, 10, 15, 20].map(start => (
                  <OptionRow key={start}>
                    {
                      options ? options.slice(start, start + 5).map((option, optionIndex) => {

                        return (
                            <Option
                                key={optionIndex}
                                onClick={() => onSelectOption(optionIndex + start)}
                                selectedstatus={statusByOptionIndex[optionIndex + start]}
                                disabled={done}
                            >
                              {option}
                            </Option>
                        );
                      }) : null
                    }
                  </OptionRow>
              ))
            }
            <OptionRow>
              <CommandButton onClick={submit}>ENTER</CommandButton>
              <CommandButton onClick={goBack}>BACK</CommandButton>
            </OptionRow>
          </OptionSection>
        </Container>
        <Container>
          <HowTo>
            <h3>
              HOW TO PLAY
            </h3>
            <p>
              You have {answerSets.length} chances to guess the 5 correct answers in order for today's topic.
            </p>
            <p>
              Choose 5 answers then click Enter to see the results of your current guess.
            </p>
            <Row>
              <Entry
                  selectedstatus={1}
                  isfirst={true}
              >
                Chocolate
              </Entry>
              <Entry
                  selectedstatus={3}
              >
                Vanilla
              </Entry>
              <Entry
                  selectedstatus={2}
              >
                Strawberry
              </Entry>
              <Entry
                  selectedstatus={3}
              >
                Pecan
              </Entry>
              <Entry
                  selectedstatus={3}
                  islast={true}
              >
                Fudge
              </Entry>
            </Row>
            <p>
              Correct answers that are also in the correct position will turn green. e.g. Chocolate is correct, and in the right position.
            </p>
            <p>
              Correct answers that are NOT in the correct position will turn yellow. You will have a chance in later guesses to find the correct position. e.g. Strawberry is a correct answer, but does not belong in position 3.
            </p>
            <p>
              If you can find the 5 correct answers in the right order than you win!
            </p>
            <p>
              Come back each day for a new question / topic!
            </p>
          </HowTo>
        </Container>
      </Body>
    </AppWrapper>
  );
}

export default App;
